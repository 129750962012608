<template>
  <div class="notice list-page">
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>通知公告</h1>
          <p>Notice announcement</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button class="active" @click="handleAdd"><img src="@/assets/imgs/add.png" />新增</el-button>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="content" label="通知内容"></el-table-column>
        <el-table-column prop="fixedTime" label="开始时间"></el-table-column>
        <el-table-column prop="expireTime" label="失效时间"></el-table-column>
        <el-table-column prop="status" label="状态" :formatter="formatterStatus"></el-table-column>
        <el-table-column label="操作" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleEdit(row)">修改</el-button>
            <el-button @click="handlePublish(row)">发布</el-button>
            <el-button @click="handleDelete(row)" class="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 修改弹框 -->
    <el-dialog v-model="editDialogVisible" width="600px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}通知公告</h1>
        <p>{{curEditLabel.en}} notice announcement</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" :rules="editFormRules" class="edit-form">
        <el-form-item label="通知内容" prop="content">
          <el-input v-model="editFormData.content" :rows="3" type="textarea"  placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="fixedTime">
          <el-date-picker v-model="editFormData.fixedTime" type="datetime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效时间" prop="expireTime">
          <el-date-picker v-model="editFormData.expireTime" type="datetime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择"></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { sys } from '@/api'

export default {
  name: 'Notice',
  data() {
    return {
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      statusList: [
        {
          label: '草稿',
          value: 'DRAFT'
        },
        {
          label: '待展示',
          value: 'SHOW_PENDING'
        },
        {
          label: '展示中',
          value: 'SHOW'
        },
        {
          label: '已失效',
          value: 'EXPIRED'
        }
      ],

      editDialogVisible: false,
      initEditFormData: { 
        content: '',
        fixedTime: '',
        expireTime: ''
      },
      editFormData: {...this.initEditFormData}, // 修改表单
      curEditLabel: {
        zh: '',
        en: '',
      },
      editFormRules: {
        content: { required: true, message: '请输入', trigger: 'blur' },
      },
    }
  },
  created() {
    this.loadTableData()
  },
  methods: {
    // 获取列表数据
    async loadTableData() {
      let params = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await sys.getNoticeList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 点击新增
    handleAdd() {
      this.editFormData = this.initEditFormData
      this.curEditLabel.zh = '新增'
      this.curEditLabel.en = 'Add'
      this.editDialogVisible = true
    },
    // 点击修改
    handleEdit(row) {
      this.editFormData = { ...row }
      this.curEditLabel.zh = '修改'
      this.curEditLabel.en = 'Edit'
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {
            ...this.editFormData,
          }

          const {code, msg} = await sys.saveNotice(params)
          if(code === 0) {
            this.editDialogVisible = false
            this.$message.success(`${this.curEditLabel.zh}成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
    // 发布
    async handlePublish({id}) {
      this.$confirm('确认发布改公告通知吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.publishNotice({id})
        if(code === 0) {
          this.$message.success(`发布成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消发布')
      })
    },
     // 点击删除
    handleDelete({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.delNotice({id})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
    // 格式化资产状态
    formatterStatus(row, column, cellValue) {
      let label = cellValue
      for(let item of this.statusList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
  }
}
</script>

<style lang="scss" scoped>
.notice {
  .edit-form {
    .el-form-item {
      ::v-deep .el-form-item__label {
        width: 80px;
        color: #B4B4C5;
      }
      ::v-deep .el-form-item__content {
        .el-date-editor.el-input {
          width: 100%;
        }
      }
    }
  }
}
</style>